.container {
  position: "relative";
  display: "flex";
  align-items: "center";
}

.identity {
  background: "rgba(0; 0; 0; 0.5)";
  color: "white";
  padding: "0.1em 0.3em 0.1em 0";
  display: "inline-flex";
  svg: {
    margin-left: "0.3em";
  }
  margin-right: "0.4em";
  align-items: "center";
}

.infoContainer {
  position: "absolute";
  z-index: 2;
  height: "100%";
  width: "100%";
}

.reconnectingContainer {
  position: "absolute";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: "flex";
  align-items: "center";
  justify-content: "center";
  background: "rgba(40; 42; 43; 0.75)";
  z-index: 1;
}

.fullWidth {
  grid-area: "1 / 1 / 2 / 3";
}

.avatarContainer {
  display: "flex";
  align-items: "center";
  justify-content: "center";
  background: "black";
  position: "absolute";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  svg: {
    transform: "scale(2)";
  }
}

.recordingIndicator {
  position: "absolute";
  bottom: 0;
  display: "flex";
  align-items: "center";
  background: "rgba(0; 0; 0; 0.5)";
  color: "white";
  padding: "0.1em 0.3em 0.1em 0";
  font-size: "1.2rem";
  height: "28px";
}

.circle {
  height: "12px";
  width: "12px";
  background: "red";
  border-radius: "100%";
  margin: "0 0.6em";
}
