@import "../../../../../../../common/styles/mixins";

.wrapper {
  @include layout-flip {
    align-items: center;
    padding: 0 22px;
  }
}

.root {
  max-width: 781px;
  width: 100%;
  max-height: 80%;
  padding: 32px 32px 60px;
  background: #ffffff;
  border-radius: 15px;
  overflow: auto;
  scrollbar-color: #68768b rgba(31, 52, 47, 0.3);
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(31, 52, 47, 0.3);
    border-radius: 15px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #68768b;
    border-radius: 15px;
  }

  @include layout-flip {
    max-height: calc(100vh - 100px);
    padding: 24px;
  }

  .inner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .closeButton {
    @include clean-btn;
    align-self: flex-end;
    margin: 0 0 9px;

    svg path {
      stroke: #1f342f;
    }

    @include layout-flip {
      margin: 0 0 17px;
    }
  }

  .title {
    margin: 0 0 40px;
    text-align: center;
    font-family: $headings-font-family;
    font-weight: 400;
    font-size: 46px;
    line-height: 1.1;
    color: #1f342f;

    @include layout-flip {
      margin: 0 0 16px;
      font-size: 32px;
      line-height: 1.2;
    }
  }

  .subtitle {
    margin: 0 0 40px;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.1;
    text-align: center;
    color: #68768b;

    @include layout-flip {
      margin: 0 0 32px;
      font-size: 16px;
    }
  }

  .form {
    max-width: 520px;
    width: 100%;
    margin: 0 auto 40px;
    border: 1px solid #d4e1f2;
    border-radius: 6px;
    overflow: hidden;

    @include layout-flip {
      border: none;
    }
  }

  .info {
    padding: 24px 17px 0;
    border-top: 1px solid #d4e1f2;

    @include layout-flip {
      margin: 29px 0 0;
      padding: 32px 0 0;
    }
  }

  .infoItem {
    margin: 0 0 20px;
    padding: 0;

    @include layout-flip {
      margin: 0 0 25px;
      max-width: 280px;
    }
  }

  .rowButtons {
    display: flex;
    justify-content: space-between;
    max-width: 520px;
    width: 100%;

    @include layout-flip {
      flex-direction: column;
    }
  }

  .button {
    max-width: 250px;
    width: 100%;

    @include layout-flip {
      max-width: 100%;
      padding: 18px 47px;

      &:not(:last-child) {
        margin: 0 0 12px;
      }
    }
  }
}
