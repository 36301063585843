@import "../../../../common/styles/variables.scss";
@import "../../../../common/styles/mixins";

.overview {
  display: flex;
  flex-direction: column;

  .greeting {
    margin: 0 0 56px;

    @include layout-flip {
      display: none;
    }

    h1 {
      margin: 7px 0 16px 0;
      font-family: "Mulish", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 46px;
      line-height: 58px;
    }

    small {
      font-family: "Public Sans", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      letter-spacing: -0.01em;
      color: #808080;
    }
  }

  .table {
    width: 100%;
  }

  .tableHeader {
    @include layout-flip {
      display: none;
    }
  }

  .tableHead {
    flex: 0 0 20%;
    padding: 0 10px 32px;
    text-align-last: left;
    font-family: "Public Sans", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.1;
    letter-spacing: 0.04em;
    color: #808080;

    &.sortable {
      display: flex;
      align-items: center;

      svg {
        margin: auto auto auto 8px;
        flex-shrink: 0;
      }
    }
  }

  .tableLine {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 -10px;
    padding: 0 40px;

    @include layout-flip {
      display: grid;
      margin: 0;
      padding: 0;
      border-bottom: 1px solid #ccdaee;

      &:not(:last-child) {
        margin: 0 0 24px;
      }
    }
  }

  .tableBody {
    display: flex;
    flex-direction: column;
    box-shadow: 0 20px 30px rgba(197, 209, 226, 0.5);
    background: #ffffff;
    border-radius: 10px;

    @include layout-flip {
      background: none;
      box-shadow: none;
    }

    tr {
      &:last-of-type {
        td {
          border-bottom: none;
        }
      }
    }
  }

  .tableCell {
    display: flex;
    align-items: center;
    flex: 0 0 20%;
    min-height: 122px;
    padding: 0 10px;
    font-family: "Public Sans", sans-serif;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    color: #6e7891;
    border-bottom: 1px solid #808080;

    @include layout-flip {
      width: 100%;
      min-height: auto;
      margin: 0 0 16px;
      padding: 0;
      border: none;
    }

    a {
      color: inherit;
      font-size: inherit;
      line-height: inherit;
    }
  }

  .tableButton {
    max-width: 176px;
    width: 100%;
    margin: 0 0 0 auto;
    padding: 15px 5px;

    @include layout-flip {
      max-width: 100%;
      margin: 0 0 8px;
    }
  }
}
