@import "../../styles/variables";
@import "../../styles/mixins";

.search {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: calc($btn-height - 10px);
  margin-bottom: 8px;
  width: 100%;
  padding: $input-padding;
  background-color: $input-background-color;
  border: $input-border-width solid $input-border-color;
  border-radius: $input-border-radius;
  transition: all 0.3s ease-in-out 0s;

  @include media-breakpoint-up(md) {
    height: $input-height;
  }

  &:hover,
  &:focus,
  &.active {
    border: 1px solid #4adccc;
  }

  &__input {
    padding: 0;
    width: calc(100% - 40px);
    font-size: 20px;
    line-height: 19px;
    border: none;
    background: transparent;
    outline: none;
    color: #1f342f;
    transition: all 0.2s ease-out;

    &::placeholder {
      font-size: 16px;
    }
  }

  &__icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-right: 10px;
  }

  &__selected {
    padding: 10px;
    background: #3ceac1;
    border-radius: 50px;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    white-space: nowrap;
  }
}