.outerContainer {
  width: "2em";
  height: "2em";
  padding: "0.9em";
  display: "flex";
  align-items: "center";
  justify-content: "center";
  background: "rgba(0; 0; 0; 0.5)";
}

.innerContainer {
  display: "flex";
  align-items: "flex-end";
}
