@import "../../../../../common/styles/mixins";

.root {
  z-index: 1000;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .closeButton {
    @include clean-btn;
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 1001;
    padding: 14px;
    background: $primary;
    border-radius: 10px;

    svg {
      width: 27px;
      height: 27px;

      path {
        fill: $secondary;
      }
    }
  }

  .image {
    display: block;
    width: 100%;
    height: 100%;
    background: #c4c4c4;
    border-radius: 10px;
    object-fit: contain;
  }

  .inner {
    margin: auto;
    max-width: 88%;
    max-height: 70%;
  }
}
