.container {
  background: "white";
  padding-left: "1em";
  display: "none";
}

.endCallButton {
  height: "28px";
  font-size: "0.85rem";
  padding: "0 0.6em";
}
