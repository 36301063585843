@import "../../../common/styles/variables";
@import "../../../common/styles/mixins";

.cardCodeModal {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 487px;
  width: 100%;
  padding: 60px 52px 32px;
  background: #ffffff;
  border-radius: 15px;

  @include layout-flip {
    align-items: flex-start;
    max-width: none;
    padding: 56px 23px;

    &::after {
      content: "";
      align-self: center;
      position: absolute;
      top: 15px;
      width: 12%;
      height: 2px;
      background: #a7b3ce;
      border-radius: 20px;
    }
  }

  .closeButton {
    @include clean-btn;
    position: absolute;
    top: 32px;
    right: 32px;

    @include layout-flip {
      display: none;
    }
  }

  h1 {
    margin: 0 0 32px;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 38px;
    color: $secondary;

    @include layout-flip {
      margin: 0 0 24px;
      font-size: 23px;
      line-height: 34px;
      letter-spacing: 0.25px;
    }
  }

  .cardInfo {
    width: 100%;
    margin: 0 0 24px;
    padding: 24px;
    border: 1px solid $gray-200;
    border-radius: 10px;

    @include layout-flip {
      width: auto;
      padding: 24px 16px;
      border-radius: 20px;
    }

    .top {
      display: flex;
      align-items: center;

      @include layout-flip {
        flex-direction: column;
        align-items: flex-start;
        margin: 0 0 16px;
      }

      svg {
        width: 65px;
        height: 46px;
        margin: 0 16px 0 0;

        @include layout-flip {
          width: 41px;
          height: 29px;
          margin: 0 0 8px;
        }
      }

      .mainInfo {
        h2 {
          margin: 0 0 8px;
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 18px;
          letter-spacing: -0.01em;
          color: $secondary;

          @include layout-flip {
            font-size: 14px;
            line-height: 16px;
            margin: 0 0 4px;
          }
        }

        p {
          margin: 0;
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 18px;
          letter-spacing: -0.01em;
          color: $gray-700;

          @include layout-flip {
            font-size: 14px;
            line-height: 16px;
          }
        }
      }
    }

    button {
      margin: 16px 0 0;
      padding: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.01em;
      color: $primary;
      text-decoration: underline;
    }
  }

  form {
    width: 100%;

    .input {
      margin: 0 0 24px;
      max-width: 160px;
    }
  }
}
