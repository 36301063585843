@import "../../../../../../../common/styles/mixins";

.wrapper {
  @include layout-flip {
    align-items: center;
  }
}

.modal {
  position: relative;
  background-color: #fff;
  border-radius: 15px;
  max-width: 660px;
  padding: 50px 60px;
  display: flex;
  align-items: center;
  flex-direction: column;

  @include layout-flip {
    margin: 0 24px;
    padding: 40px 15px;
  }

  .closeButton {
    @include clean-btn;
    position: absolute;
    top: 20px;
    right: 20px;
  }

  h1 {
    margin: 0 0 30px;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 46px;
    line-height: 58px;
    color: $secondary;

    @include layout-flip {
      margin: 0 0 20px;
      font-size: 30px;
      line-height: 38px;
    }
  }

  .buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 40px;
    width: 100%;

    @include layout-flip {
      grid-template-columns: 1fr;
      grid-row-gap: 20px;
    }
  }
}
