@import "../../../common/styles/_variables.scss";

.radiobutton {
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  font-size: 16px;
  color: $gray-700;
  width: auto;
  padding: 12px 0;

  &__text p {
    margin: 0;
  }

  &--outlined {
    border: 1px solid $gray-200;
    border-radius: 4px;
    padding: 16px 24px;
    transition: all 0.3s ease-in-out 0s;

    &.checked {
      background: $primary;
      border-color: $gray-700;

      .radiobutton__text {
        color: $secondary;
      }

      .radiobutton__button {
        border: 6px solid #ffffff !important;
      }
    }

    &:hover,
    &:focus {
      border: 1px solid #4adccc;
    }
  }
  .radiobutton__inner {
    display: flex;
    align-items: center;
  }

  &__input {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);

    &:checked + .radiobutton__inner .radiobutton__button {
      border: 6px solid $primary;
    }
  }

  &__button {
    position: relative;
    margin-right: 10px;
    width: 24px;
    min-width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid $gray-200;
    box-sizing: border-box;
    box-shadow: 0 15px 30px rgba(77, 198, 155, 0.2);
  }

  &.is-button {
    padding: 0;

    .radiobutton__button {
      width: auto;
      height: 39px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin: 0;
      padding: 4px 16px;
      color: $secondary;
      border: 1px solid $gray-200;
      border-radius: 6px;
    }

    & .radiobutton__input {
      &:checked + .radiobutton__inner .radiobutton__button {
        background: $primary;
        border: 1px solid $primary;
        box-shadow: 0 5px 20px rgba(73, 228, 211, 0.4);
      }

      &:focus + .radiobutton__inner .radiobutton__button {
        box-shadow: 0 5px 20px rgba(73, 228, 211, 0.4);
      }
    }
  }
}
