@import "../../../common/styles/variables";
@import "../../../common/styles/mixins";

.root {
  margin-top: 25px;
  order: 100;
}

.addButton {
  width: auto;

  @include layout-flip {
    width: 100%;
  }

  // Should be in every button
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 24px;
    height: 24px;
    margin: 0 10px 0 0;
    flex-shrink: 0;
  }
}

.closeButton {
  position: absolute;
  top: 36px;
  right: 36px;
  @include clean-btn;

  @include layout-flip {
    display: none;
  }
}

.card {
  max-width: 333px;

  .testimonialRank {
    svg + svg {
      margin-left: 10px;
    }
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: -0.01em;
    margin: 15px 0;
    word-break: break-word;

    color: #68768b;
  }
}

.modal {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  width: 100%;
  padding: 50px 100px;
  background: white;
  border-radius: 15px;

  @include layout-flip {
    max-width: none;
    padding: 20px 24px 50px;
    border-radius: 30px 30px 0 0;

    .mobileLine {
      width: 15%;
      height: 2px;
      margin-bottom: 28px;
      background: #a7b3ce;
    }
  }

  h1 {
    margin: 0 0 40px;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 38px;
    color: $secondary;

    @include layout-flip {
      margin: 0 0 32px;
      font-size: 23px;
      line-height: 34px;
      letter-spacing: 0.25px;
    }
  }

  .confirmation {
    margin: 0 0 24px;
  }
}

.textarea {
  width: 100%;
  margin-bottom: 40px;

  @include layout-flip {
    margin-bottom: 32px;
  }
}

.saveButton {
  min-width: 250px;

  @include layout-flip {
    width: 100%;
  }
}
