@import "../../../../../common/styles/variables.scss";
@import "../../../../../common/styles/mixins";

.profileInfoCard {
  width: 100%;
  max-width: 565px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 30px 30px 0;
  padding: 24px;
  background-color: white;
  border: 1px solid $gray-200;
  border-radius: 10px;

  @include layout-flip {
    flex-direction: column;
    flex-grow: 1;
    margin: 0 0 16px;
    padding: 0;
    background: none;
    border: none;
  }

  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    color: $secondary;

    @include layout-flip {
      order: 2;
    }
  }

  small {
    display: block;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: $gray-700;

    @include layout-flip {
      order: 3;
    }
  }

  .avatar {
    margin: 0 26px 0 0;

    .image {
      position: relative;
      width: fit-content;

      @include layout-flip {
        margin: 0 auto 21px;
      }
    }

    &.withRemove {
      .editIcon {
        bottom: -8px;

        @include media-breakpoint-down(lg) {
          bottom: 12px;
        }
      }
    }

    img {
      width: 152px;
      height: 152px;
      border-radius: 15px;
      object-fit: cover;

      @include layout-flip {
        width: 80px;
        height: 80px;
      }
    }

    .editIcon {
      cursor: pointer;
      position: absolute;
      right: -23px;
      bottom: -12px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 48px;
      height: 48px;
      min-height: auto !important;
      min-width: auto !important;
      padding: 0 !important;
      background-color: white;
      border-radius: 50px;
      box-shadow: 0 20px 30px rgba(197, 209, 226, 0.5);

      @include layout-flip {
        right: -16.6px;
        bottom: -11px;
        width: 36px;
        height: 36px;
      }

      svg {
        width: 48px;
      }
    }
  }

  .removeAvatar {
    @include clean-btn;

    justify-content: flex-start;
    width: auto;
    text-align: left;
    font-family: $headings-font-family;
    font-weight: 600;
    font-size: 14px;
    line-height: 1;
    color: #26d5b6;
    border-bottom: 1px solid #26d5b6;
    transition: all 0.3s ease-in-out 0s;

    &:hover,
    &:focus {
      border-color: transparent;
    }

    @include media-breakpoint-down(lg) {
      margin: 8px 0 0;
    }
  }

  .info {

    .infoWrap {
      display: flex;

      @include layout-flip {
        flex-direction: row-reverse;
      }
    }

    @include layout-flip {
      margin: 0;
    }

    h2 {
      margin: 14px 0 8px;

      span {
        width: fit-content;
      }

      @include layout-flip {
        display: block;
        text-align: center;
        font-size: 23px;
        line-height: 34px;
        letter-spacing: 0.25px;
      }
    }

    small {

      @include layout-flip {
        text-align: center;
        margin: 0 0 16px;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: -0.01em;
      }
    }

    .credentialsStatus {
      margin: 16px 0 0 15px;

      @include layout-flip {
        margin: 18px 0 0 15px;
      }

      .credentialsStatusIcon {
        width: 25px;
        height: 25px;
      }
    }
  }

  .buttonBlock {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 5px 0;

    @include layout-flip {
      padding: 0;
    }

    .viewProfileButton {
      max-width: 225px;
      padding: 15px 48px;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.01em;
      border: 1px solid $primary;
      border-radius: 5px;
      white-space: nowrap;

      @include layout-flip {
        margin: 0 0 8px;
        padding: 11px 17px;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0.01em;
      }
    }

    .link {
      text-align: center;
      padding: 15px 6px;
      color: #68768B;
      background-color: inherit;
      border: 1px solid #D4E1F2;
      border-radius: 4px;
      overflow: hidden;

      @include layout-flip {
        margin: 0 0 8px;
        padding: 11px 6px;
      }
    }
  }
}
