@import "../../../../common/styles/mixins";
@import "../../../../common/styles/mixins.scss";

.wrapper {
  @include layout-flip {
    align-items: center;
  }
}

.modal {
  position: relative;
  background-color: #fff;
  border-radius: 15px;
  max-width: 487px;
  padding: 50px 80px;
  display: flex;
  align-items: center;
  flex-direction: column;

  @include layout-flip {
    margin: 0 24px;
    padding: 50px 40px;
  }

  &_close_button {
    position: absolute;
    top: -30px;
    right: -30px;
    margin: 54px;
    background: none;
    border: none;
    cursor: pointer;

    svg {
      path {
        stroke: $secondary;
      }
    }
  }

  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 35px;
    line-height: 44px;

    color: $secondary;
    margin: 0 0 15px;

    @include layout-flip {
      font-size: 30px;
      line-height: 38px;
    }
  }

  p {
    font-family: $headings-font-family;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    margin: 0;
    text-align: center;

    color: $secondary;

    @include layout-flip {
      font-size: 15px;
      line-height: 25px;
    }
  }

  a {
    color: #26d4b6;
  }
}
