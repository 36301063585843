@import "../../styles/variables";

.react-select {
  .react-select {
    &__placeholder {
      color: $gray-700;
      opacity: 0.5;
    }

    &__control {
      font-size: 16px;
      cursor: pointer;
      border: 1px solid #d4e1f2;
      border-radius: 4px;
      line-height: 19px;
      color: #6e7891;
      padding: 10px 6px 11px;

      &:hover,
      &:focus {
        border-color: #3ceac1;
      }
    }
  }

  &__indicator-separator {
    display: none;
  }

  .css-1pahdxg-control {
    border-color: #3ceac1;
    box-shadow: none;
  }

  &__container {
    z-index: 10;
  }
}

.small .react-select {
  &__control {
    padding: 0;
  }
}

.placeholder-as-label .react-select__placeholder {
  opacity: 100%;
}

.high-index {
  z-index: 11;
}
