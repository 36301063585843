@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?l3aai4') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?l3aai4') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?l3aai4##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icomoon-"], [class*=" icomoon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icomoon-lamp {
  &:before {
    content: $icomoon-lamp; 
  }
}
.icomoon-checkmark {
  &:before {
    content: $icomoon-checkmark; 
  }
}

