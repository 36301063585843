.container {
  overflow-y: "auto";
  background: "rgb(79; 83; 85)";
  grid-area: "1 / 2 / 1 / 3";
  z-index: 5;
}

.transparentBackground {
  background: "transparent";
}

.scrollContainer {
  display: "flex";
  justify-content: "center";
}

.innerScrollContainer {
  padding: "1.5em 0";
}
