@import "../../../../common/styles/variables.scss";
@import "../../../../common/styles/mixins.scss";

.modal {
  align-items: center;
}

.popup {
  position: relative;
  max-width: 327px;
  width: 100%;
  padding: 50px 15px 30px;
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(150, 167, 226, 0.1);
  border-radius: 15px;
}

.popupCloseButton {
  position: absolute;
  top: 0;
  right: 0;
  margin: 15px;
  background: none;
  border: none;
  cursor: pointer;
}

.popupTitle {
  margin: 0 0 20px;
  padding: 0;
  text-align: center;
  font-family: $headings-font-family;
  font-weight: 400;
  font-size: 30px;
  line-height: 38px;
  color: $secondary;

  @include layout-flip {
    display: none;
  }
}

.btn {
  width: 100%;
  margin-bottom: 10px;
}
