.container {
  position: "relative";
  height: 0;
  overflow: "hidden";
  background: "black";
}
.innerContainer {
  position: "absolute";
  top: 0;
  left: 0;
  width: "100%";
  height: "100%";
}
.identityContainer {
  position: "absolute";
  bottom: 0;
  z-index: 1;
}
.identity {
  background: "rgba(0; 0; 0; 0.5)";
  color: "white";
  padding: "0.18em 0.3em";
  margin: 0;
  display: "flex";
  align-items: "center";
}
.avatarContainer {
  display: "flex";
  align-items: "center";
  justify-content: "center";
  background: "black";
  position: "absolute";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}
