.container {
  isolation: "isolate";
  position: "relative";
  display: "flex";
  align-items: "center";
  height: 0;
  overflow: "hidden";
  margin-bottom: "0.5em";
  border-radius: "4px";
  background: "black";
}

.innerContainer {
  position: "absolute";
  top: 0;
  left: 0;
  width: "100%";
  height: "100%";
}

.infoContainer {
  position: "absolute";
  z-index: 2;
  display: "flex";
  flex-direction: "column";
  justify-content: "space-between";
  height: "100%";
  width: "100%";
  background: "transparent";
  top: 0;
}

.avatarContainer {
  display: "flex";
  align-items: "center";
  justify-content: "center";
  background: "black";
  position: "absolute";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.reconnectingContainer {
  position: "absolute";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: "flex";
  align-items: "center";
  justify-content: "center";
  background: "rgba(40; 42; 43; 0.75)";
  z-index: 1;
}

.identity {
  background: "rgba(0; 0; 0; 0.5)";
  color: "white";
  padding: "0.18em 0.3em 0.18em 0";
  margin: 0;
  display: "flex";
  align-items: "center";
}

.infoRowBottom {
  display: "flex";
  justify-content: "space-between";
  position: "absolute";
  bottom: 0;
  left: 0;
}

.typography {
  color: "white";
}

.hideParticipant {
  display: "none";
}

.cursorPointer {
  cursor: "pointer";
}

.galleryView {
  border-radius: "8px";
}
