@import "../../../../../common/styles/variables.scss";
@import "../../../../../common/styles/mixins.scss";

.root {
  @include layout-flip {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.popup {
  position: relative;
  max-width: 487px;
  width: 100%;
  padding: 50px;
  background: #ffffff;
  box-shadow: 0 10px 20px rgba(150, 167, 226, 0.1);
  border-radius: 15px;

  @include layout-flip {
    display: block;
    max-width: 327px;
    width: 100%;
    padding: 40px 15px;
    border-radius: 15px;
  }
}

.popupContainer {
  max-width: 387px;
  margin: 0 auto;

  @include media-breakpoint-down(sm) {
    max-width: 327px;
  }
}

.popupCloseButton {
  position: absolute;
  top: 0;
  right: 0;
  margin: 20px;
  background: none;
  border: none;
  cursor: pointer;

  @include layout-flip {
    top: 15px;
    right: 15px;
    margin: 0;
    padding: 0;
  }
}

.popupTitle {
  margin: 0 0 32px;
  padding: 0;
  text-align: center;
  font-family: $headings-font-family;
  font-weight: 400;
  font-size: 30px;
  line-height: 1.3;
  color: $secondary;

  @include media-breakpoint-down(sm) {
    margin: 0 0 20px;
  }
}

.popupSubtitle {
  display: block;
  margin: 0 0 16px;
  padding: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
  color: $secondary;
}

.popupInput {
  width: 100%;
  margin: 0 0 32px;

  @include media-breakpoint-down(sm) {
    margin: 0 0 17px;
  }
}

.popupButton {
  max-width: 100%;
  width: 100%;
  margin: 0 0 0;

  @include media-breakpoint-down(sm) {
    margin: 0;
  }
}

.error {
  margin: 0 0 20px;
  padding: 0;
  color: $red;
  font-size: 14px;
}
