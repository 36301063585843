.gutterbottom {
  margin-bottom: "1em";
}

.margintop {
  margin-top: "1em";
}

.devicebutton {
  width: "100%";
  border: "2px solid #aaa";
  margin: "1em 0";
}

.localpreviewcontainer {
  padding-right: "2em";
  margin-bottom: "2em";
}

.joinbuttons {
  display: "flex";
  justify-content: "space-between";
}

.mobilebutton {
  padding: "0.8em 0";
  margin: 0;
}

.tooltipcontainer {
  display: "flex";
  align-items: "center";
  div {
    display: "flex";
    align-items: "center";
  }
  svg {
    margin-left: "0.3em";
  }
}
